<template>
  <div class="RefundInformation">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <span style="height: 40px; line-height: 40px">退款类型：</span>
          <el-select
            v-model="refundType"
            placeholder="请选择"
            style="width: 200px; margin-right: 10px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="正常退费" value="0"></el-option>
            <el-option label="特殊退费" value="1"></el-option>
          </el-select>
          <span style="height: 40px; line-height: 40px">退款状态：</span>
          <el-select
            v-model="statusRefund"
            placeholder="请选择"
            style="width: 200px; margin-right: 10px"
          >
            <el-option label="退款成功" value="5"></el-option>
            <el-option label="退款失败" value="6"></el-option>
            <el-option label="退款中" value="4"></el-option>
          </el-select>
          <span style="height: 40px; line-height: 40px">审核状态：</span>
          <el-select
            v-model="auditStatus"
            placeholder="请选择"
            style="width: 200px; margin-right: 10px"
          >
            <el-option label="待审核" value="0"></el-option>
            <el-option label="审核成功" value="1"></el-option>
            <el-option label="审核失败" value="2"></el-option>
          </el-select>

          <span style="height: 40px; line-height: 40px">代表队：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="unitName"
          ></el-input>
          <span style="height: 40px; line-height: 40px">退赛选手：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="playerName"
          ></el-input>
        </div>
        <div class="btn2">
          <span style="height: 40px; line-height: 40px">赛事名称：</span>
          <el-input
                  style="margin-right: 20px; width: 200px"
                  v-model="raceName"
          ></el-input>
          <span style="height: 40px; line-height: 40px">组别名称：</span>
          <el-input
                  style="margin-right: 20px; width: 200px"
                  v-model="groupName"
          ></el-input>
          <span style="height: 40px; line-height: 40px">申请日期：</span>
          <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="startTime"
                  style="width: 200px"
                  value-format="yyyy-MM-dd"
          ></el-date-picker>
          ~
          <el-date-picker
                  placeholder="选择日期"
                  v-model="endTime"
                  value-format="yyyy-MM-dd"
                  style="width: 200px"
          ></el-date-picker>
          <el-button
            style="height: 40px; margin-left: 10px"
            type="primary"
            plain
            @click="searchSchedule"
            >查询</el-button
          >
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportApplyRefund"
            >导出</el-button
          >
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="580"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="申请时间" width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.applyTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单号" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.orderId }}</span>
            </template>
          </el-table-column>
          <el-table-column label="赛事名称" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.competitionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.unitName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退赛选手" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.playerNames }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.groupName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款金额" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.refundMoney / 100 }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="退款原因" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.applyReason }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款状态" width="100">
            <template slot-scope="scope">
              <span>{{
                scope.row.refundStatus == 5
                  ? "退款成功"
                  : scope.row.refundStatus == 6
                  ? "退款失败"
                  : scope.row.refundStatus == 4
                  ? "退款中"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" width="100">
            <template slot-scope="scope">
              <span>{{
                scope.row.auditStatus == 0
                  ? "待审核"
                  : scope.row.auditStatus == 1
                  ? "审核成功"
                  : scope.row.auditStatus == 2
                  ? "审核失败"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款到账时间" width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.refundTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款类型" width="100">
            <template slot-scope="scope">
              <span>{{
                scope.row.applyType == 0
                  ? "正常退费"
                  : scope.row.applyType == 1
                  ? "特殊退费"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                v-if="scope.row.auditStatus == 0 && scope.row.applyType == 1"
                @click="checkRefund(scope.$index, scope.row)"
                >审核</el-button
              >
              <el-button
                size="mini"
                type="info"
                disabled
                v-else-if="
                  scope.row.auditStatus != 0 && scope.row.applyType == 1
                "
                >已审核</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="审核退款"
      :visible.sync="dialogVisible"
      width="30%"
      @close="dialogClose"
    >
      <el-form :model="formModel" label-width="80px">
        <el-form-item label="选手姓名:">
          <el-input :disabled="true" v-model="formModel.playerNames"></el-input>
        </el-form-item>
        <el-form-item label="退款金额:">
          <el-input :disabled="true" v-model="formModel.refundMoney"></el-input>
        </el-form-item>
        <el-form-item label="退款原因:">
          <el-input :disabled="true" v-model="formModel.applyReason" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="证明图片:">
          <el-image
            v-for="(img, index) in formModel.pictures"
            :key="index"
            style="width: 100px; height: 100px"
            :src="img"
            fit="cover"
           :preview-src-list="formModel.pictures"
          ></el-image>
        </el-form-item>
        <el-form-item label="审核理由:">
          <el-input type="textarea" v-model="applyReason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm(1)">通过</el-button>
        <el-button type="primary" @click="confirm(2)">不通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formModel: {},
      applyReason: "",
      RefundId: "",
      statusRefund: "",
      auditStatus: "",
      refundType: "",
      raceName: "",
      groupName: "",
      playerName: "",
      unitName: "",
      startTime: "",
      endTime: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      dialogVisible: false,
    };
  },
  methods: {
    dialogClose() {
      this.applyReason = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.findRefundInfo();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.findRefundInfo();
    },
    //获取所有报名退款信息
    async findRefundInfo() {
      try {
        const result = await this.$api.findRefundInfo(
          `currentPage=${this.currentPage}&pageSize=${
            this.pageSize
          }&search_EQ_refundStatus=${
            this.statusRefund
          }&search_EQ_auditStatus=${
            this.auditStatus
          }&search_LIKE_competitionName=${
            this.raceName
          }&search_EQ_groupName=${
            this.groupName
          }&search_LIKE_unitName=${
            this.unitName
          }&search_LIKE_playerName=${
            this.playerName
          }&search_EQ_applyDateStart=${
            this.startTime ? this.startTime : ""
          }&search_EQ_applyDateEnd=${
            this.endTime ? this.endTime : ""
          }&search_EQ_applyType=${this.refundType}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item && item.playerNames) {
            item.playerNames = JSON.parse(item.playerNames).join(",");
          }
        });
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //查询所有报名退款信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.findRefundInfo(
          `currentPage=${this.currentPage}&pageSize=${
            this.pageSize
          }&search_EQ_refundStatus=${
            this.statusRefund
          }&search_EQ_auditStatus=${
            this.auditStatus
          }&search_LIKE_competitionName=${
            this.raceName
          }&search_EQ_groupName=${
            this.groupName
          }&search_LIKE_unitName=${
            this.unitName
          }&search_LIKE_playerName=${
            this.playerName
          }&search_EQ_applyDateStart=${
            this.startTime ? this.startTime : ""
          }&search_EQ_applyDateEnd=${
            this.endTime ? this.endTime : ""
          }&search_EQ_applyType=${this.refundType}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item && item.playerNames) {
            item.playerNames = JSON.parse(item.playerNames).join(",");
          }
        });
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    checkRefund(index, row) {
      console.log(row);
      this.RefundId = row.id;
      this.formModel = JSON.parse(JSON.stringify(row));
      this.formModel.refundMoney /= 100;
      this.formModel.pictures = JSON.parse(this.formModel.pictures);
      console.log(this.formModel);
      this.dialogVisible = true;
    },
    async confirm(status) {
      if (status == 1) {
        this.formModel.refundMoney *= 100;
        this.dialogVisible = false;
        try {
          const result = await this.$api.checkRefund(
            `id=${this.RefundId}&auditStatus=${status}`
          );
          console.log(result);
          this.findRefundInfo();
        } catch (error) {
          console.log(error);
        }
      } else if (status == 2) {
        if (this.applyReason == "") {
          this.$message({
            type: "warning",
            message: "请输入不通过的理由",
          });
        } else {
          this.formModel.refundMoney *= 100;
          this.dialogVisible = false;
          try {
            const result = await this.$api.checkRefund(
              `id=${this.RefundId}&auditStatus=${status}&reason=${this.applyReason}`
            );
            console.log(result);
            this.findRefundInfo();
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    async exportApplyRefund() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportApplyRefund(
          `search_EQ_refundStatus=${
            this.statusRefund
          }&search_EQ_auditStatus=${
            this.auditStatus
          }&search_LIKE_competitionName=${
            this.raceName
          }&search_LIKE_unitName=${
            this.unitName
          }&search_LIKE_playerName=${
            this.playerName
          }&search_EQ_applyDateStart=${
            this.startTime ? this.startTime : ""
          }&search_EQ_applyDateEnd=${
            this.endTime ? this.endTime : ""
          }&search_EQ_applyType=${this.refundType}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    this.findRefundInfo();
  },
};
</script>

<style lang="less">
.RefundInformation {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
      .btn2 {
        display: flex;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 150px;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
